import React from "react"
import { navigate, Link } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const Payments = () => {
  return (
    <React.Fragment>
      <PageTitle
        title={"Order Status"}
      />
      <Layout>
        <div className="gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>
                  {"Order Status"}
                </a>
              </li>
            </ul>
          </div>
          <div className="payment-status">
            <h1>
              {"Order Status"}
            </h1>

            <div className="page__flex">
              <p>
                Thank you for your order. We will get in touch with you shortly.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Payments
